import React from "react";

function InProgress() {
  return (
    <div className="flex justify-center items-center bg-slate-200 h-screen">
      <div className="w-1/2 h-24 bg-slate-100 rounded shadow p-3 border">
        <p className="text-lg flex justify-center flex-col items-center h-full">
          This is still under construction. Please check back later!
          <p className="font-extralight text-sm">
            Contact: rjtanenholz@gmail.com
          </p>
        </p>
      </div>
    </div>
  );
}

export default InProgress;
